import React from "react";
import { Container, Text } from "@atoms";

const List = ({ heading, list }) => {
  return (
    <Container padding className="my-10 md:my-16">
      <div className="mx-auto max-w-lg">
        <Text variant="h5" className="mb-4 text-purple">
          {heading}
        </Text>
        <hr className="h-[2px] border-0 bg-purple" />
        {list?.length > 0 && (
          <ul className="my-4 space-y-2">
            {list?.map((item, i) => {
              return (
                <Text variant="listLi" key={`item-${item}--${i + 1}`}>
                  {item}
                </Text>
              );
            })}
          </ul>
        )}
      </div>
    </Container>
  );
};

List.defaultProps = {};

export default List;
